import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import { BlogGridHighlightedPost } from './Posts/Blog'

const StyledHighlightedPostTypes = styled.section`
  background-color: ${({ theme }) => theme.color.dark};
`
const Content = styled(ParseContent)`
  & h2 {
    color: ${({ theme }) => theme.color.light};
    text-align: center;
  }
`

interface HighlightedPostTypesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_HighlightedPostTypes
}

const HighlightedPostTypes: React.FC<HighlightedPostTypesProps> = ({
  fields,
}) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedPostTypesQueryQuery>(graphql`
    query highlightedPostTypesQuery {
      allWpPost(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <StyledHighlightedPostTypes className="py-5">
      <div className="container py-lg-5 mb-4 mb-lg-0">
        <Content content={fields.description} />
      </div>
      <div className="mt-md-3">
        <BlogBink
          posts={posts as BlogBinkPosts}
          categories={allWpCategory.edges}
          id="blog"
          showIds={fields?.relation?.map((edge: any) => edge.databaseId) || []}
          limit={Infinity}
        >
          <div className="pt-lg-5 pb-5">
            <BlogGrid fields={fields} />
          </div>
        </BlogBink>
      </div>

      <div className="mt-lg-4 text-center">
        <ButtonSecondary to={fields?.link?.url || ''}>
          {fields?.link?.title}
        </ButtonSecondary>
      </div>
    </StyledHighlightedPostTypes>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_HighlightedPostTypes
}

const BlogGrid: React.FC<BlogGridProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div className="px-lg-5">
      <div className="d-flex flex-wrap">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0 px-2">
              <BlogGridHighlightedPost node={node} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HighlightedPostTypes
